<template>
  <div>
    <TopNav />
    <router-view/>
    <Footer />
  </div>
</template>
<script>
import TopNav from '@/components/layouts/TopNav.vue'
import Footer from '@/components/layouts/Footer.vue'
export default {
  name: 'App',
  components: {
    TopNav,
    Footer
  }
}
</script>