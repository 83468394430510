<template>
  <div>
    <i class="scroll-top-btn scroll-top show"></i>
    <footer class="light">
      <div class="container">
        <div class="row">
          <div class="col-lg-4">
            <h3>DARA</h3>
            <p>Digital Inpiration</p>
            <div class="icon-links icon-social icon-links-grid social-colors">
              <a class="facebook"><i class="icon-facebook"></i></a>
              <a class="twitter"><i class="icon-twitter"></i></a>
              <a class="instagram"><i class="icon-instagram"></i></a>
              <a class="pinterest"><i class="icon-youtube"></i></a>
            </div>
          </div>
          <div class="col-lg-4">
            <h3>Resources</h3>
            <ul class="icon-list icon-line">
              <li><router-link to="/">Home</router-link></li>
              <li>
                <a href="https://tech.daragroups.com" target="_blank"
                  >Technology</a
                >
              </li>
              <!-- <li>
                <a href="https://construction.daragroups.com" target="_blank"
                  >Construction</a
                >
              </li> -->
              <li><router-link to="/contacts">Contacts</router-link></li>
            </ul>
          </div>
          <!-- <div class="col-lg-4">
                        <ul class="text-list text-list-line">
                            <li><b>Address</b><hr /><p>Boulevard du 13 Janvier, Lomé</p></li>
                            <li><b>Email</b><hr /><p>info@daragroups.com</p></li>
                            <li><b>Phone</b><hr /><p>(+228) 22 22 69 89</p></li>
                            <li><b>Opening hours</b><hr /><p>8am-5pm Mon - Fri</p></li>
                        </ul>
                    </div> -->
        </div>
      </div>
      <div class="footer-bar">
        <div class="container">
          <span
            >© {{ new Date().getFullYear() }} DARA GROUPS - All Rights
            Reserved</span
          >
          <span><router-link to="/contacts">Contact us</router-link></span>
        </div>
      </div>
    </footer>
  </div>
</template>
<script>
export default {
  name: "Footer",
  mounted() {
    // this.loadJsFiles()
  },
  methods: {
    loadJsFiles() {
      var scripts = [
        "themekit/scripts/parallax.min.js",
        "themekit/scripts/glide.min.js",
        "themekit/scripts/magnific-popup.min.js",
        "themekit/scripts/tab-accordion.js",
        "themekit/scripts/imagesloaded.min.js",
        "themekit/scripts/progress.js",
        "themekit/scripts/contact-form/contact-form.js",
      ];

      scripts.forEach((script) => {
        let tag = document.createElement("script");
        tag.setAttribute("src", script);
        document.body.append(tag);
      });
    },
  },
};
</script>
