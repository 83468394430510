<template :key="templateKey">
  <main>
    <section
      class="section-image section-home-one no-padding-y"
      style="background-image: url(media/hd-1.jpg)"
    >
      <div class="container">
        <div class="row">
          <div class="col-lg-7">
            <hr class="space-lg" />
            <h3 class="text-color-2">Digital Advanced Research Agency</h3>
            <ul
              class="slider"
              data-options="arrows:false,nav:false,autoplay:3000,controls:out"
            >
              <li>
                <h1 class="text-uppercase">Technology</h1>
                <p>
                  Pioneer in advanced research, we innovate for governments,
                  organizations and small businesses
                </p>
              </li>
              <li>
                <h1 class="text-uppercase">Cyber Security</h1>
                <p>Security is our DNA.</p>
              </li>
            </ul>

            <hr class="space-sm" />
            <hr class="space-lg" />
            <hr class="space-lg" />
          </div>
          <!-- <div class="col-lg-5 hidden-md">
            <hr class="space-sm" />
            <img
              data-anima="fade-bottom"
              data-time="1000"
              class="slide-image"
              src="media/man-3.png"
              alt=""
            />
          </div> -->
        </div>
      </div>
    </section>
    <section class="section-base section-overflow-top">
      <div class="container">
        <div
          class="grid-list"
          data-columns="2"
          data-columns-md="2"
          data-columns-sm="1"
        >
          <div class="grid-box">
            <div class="grid-item">
              <div class="cnt-box cnt-box-top-icon boxed">
                <i class="im-monitor-phone"></i>
                <div class="caption">
                  <h2>TECHNOLOGY</h2>
                  <p>Today's world is driven by technology, so we are!</p>
                </div>
              </div>
            </div>
            <div class="grid-item">
              <div class="cnt-box cnt-box-top-icon boxed">
                <i class="im-data-security"></i>
                <div class="caption">
                  <h2>CYBER SECURITY</h2>
                  <p>They are ready to steal. We are ready to protect.</p>
                </div>
              </div>
            </div>
            <!-- <div class="grid-item">
              <div class="cnt-box cnt-box-top-icon boxed">
                <i class="im-building"></i>
                <div class="caption">
                  <h2>CONSTRUCTION</h2>
                  <p>Raising buildings from the ground up to the sky</p>
                </div>
              </div>
            </div> -->
          </div>
        </div>
        <div class="row row-fit-lg" data-anima="fade-bottom" data-time="1000">
          <div class="col-lg-6">
            <div class="slider" data-options="arrows:true,nav:false">
              <a
                class="img-box img-box-caption btn-video lightbox"
                href="https://www.youtube.com/watch?v=Lb4IcGF5iTQ"
                data-lightbox-anima="fade-top"
              >
                <img src="media/image-3.jpg" alt="" />
                <!-- <span>Ulrich Fangnikoue</span> -->
              </a>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="title">
              <h2>Our values and goals</h2>
              <p>About us</p>
            </div>
            <p>
              We're hungry of taking challenges and solving problems. Providing
              the most efficient solutions to the most difficult problems is our
              hallmark. We pride ourselves in understanding needs of bussinesses
              and organizations through research and development.
            </p>
            <div class="box-sign">
              <img alt="sign" src="media/sign-dark.png" /><br />
              <!-- <b>Ulrich Fangnikoue</b>
              <span>Executive Director</span> -->
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section-base section-overflow-top">
      <div class="container">
        <table
          class="table table-grid table-border align-left boxed-area table-6-md"
        >
          <tbody>
            <tr>
              <td>
                <div class="counter counter-horizontal counter-icon">
                  <i class="im-globe text-md"></i>
                  <div>
                    <h3>Countries</h3>
                    <div class="value">
                      <span class="text-md" data-to="3" data-speed="5000"
                        >3</span
                      >
                      <span>+</span>
                    </div>
                  </div>
                </div>
              </td>
              <td>
                <div class="counter counter-horizontal counter-icon">
                  <i class="im-support text-md"></i>
                  <div>
                    <h3>Team members</h3>
                    <div class="value">
                      <span class="text-md" data-to="5" data-speed="5000"
                        >5</span
                      >
                      <span>+</span>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <!-- <div class="row" data-anima="fade-bottom" data-time="1000">
                    <div class="col-lg-3">
                        <div class="title">
                            <h2>Our Team</h2>
                            <p>Innovators & Inventors</p>
                        </div>
                        <p>The amzing people behind every successful achievment</p>
                        <a href="#" class="btn-text active">View the team</a>
                    </div>
                    <div class="col-lg-9">
                        <div class="grid-list" data-columns="3" data-columns-sm="2" data-columns-xs="1">
                            <div class="grid-box">
                                <div class="grid-item">
                                    <div class="cnt-box cnt-box-team">
                                        <img src="media/man.png" alt="" />
                                        <div class="caption">
                                            <h2>Ulrich Fangnikoue</h2>
                                            <span>Executive Director</span>
                                            <span class="icon-links">
                                                <a href="#" target="_blank"><i class="icon-facebook"></i></a>
                                                <a href="#" target="_blank"><i class="icon-twitter"></i></a>
                                                <a href="#" target="_blank"><i class="icon-instagram"></i></a>
                                            </span>
                                            <p>An entrepreneurial leader with passion, perseverance and determination.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="grid-item">
                                    <div class="cnt-box cnt-box-team ">
                                        <img src="media/man.png" alt="" />
                                        <div class="caption">
                                            <h2>Tom A. Kponvi</h2>
                                            <span>Lead Software Engineer</span>
                                            <span class="icon-links">
                                                <a href="#" target="_blank"><i class="icon-facebook"></i></a>
                                                <a href="#" target="_blank"><i class="icon-twitter"></i></a>
                                                <a href="#" target="_blank"><i class="icon-instagram"></i></a>
                                            </span>
                                            <p>Leading the engineer's team to deliver reliable and efficient sofwatre systems.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="grid-item">
                                    <div class="cnt-box cnt-box-team">
                                        <img src="media/man.png" alt="" />
                                        <div class="caption">
                                            <h2>Freeman Dossou</h2>
                                            <span>Graphic Designer</span>
                                            <span class="icon-links">
                                                <a href="#" target="_blank"><i class="icon-facebook"></i></a>
                                                <a href="#" target="_blank"><i class="icon-twitter"></i></a>
                                                <a href="#" target="_blank"><i class="icon-instagram"></i></a>
                                            </span>
                                            <p>
                                                The beauty of colours and creativity lays in his eyes alone, as he designs the future.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->
      </div>
    </section>
  </main>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {
      templateKey: this.generateRandomNumber(),
    };
  },
  created() {
    this.changeTemplateKey();
  },
  methods: {
    generateRandomNumber() {
      return Math.round(Math.random() * 10000);
    },
    changeTemplateKey() {
      this.templateKey = this.generateRandomNumber();
    },
  },
};
</script>
