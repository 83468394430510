<template>
  <nav class="menu-top-logo menu-fixed" data-menu-anima="fade-in">
    <div class="container">
      <div class="menu-brand">
        <router-link to="/">
          <img class="logo-default scroll-hide" src="media/logo.png" alt="logo" />
          <img class="logo-retina scroll-hide" src="media/logo.png" alt="logo" />
          <img class="logo-default scroll-show" src="media/logo.png" alt="logo" />
          <img class="logo-retina scroll-show" src="media/logo.png" alt="logo" />
        </router-link>
      </div>
      <i class="menu-btn"></i>
      <div class="menu-cnt">
        <ul id="main-menu">
          <li><router-link to="/">Home</router-link></li>
          <li class="dropdown">
            <a href="#">Industry</a>
            <ul>
              <li>
                <a href="https://tech.daragroups.com" target="_blank">DARA TECH</a>
              </li>
              <li>
                <a href="https://sec.daragroups.com" target="_blank">DARA SEC</a>
              </li>
            </ul>
          </li>
          <li class="dropdown">
            <a href="#">Products</a>
            <ul>
              <li class="dropdown-submenu">
                <a>FINANCE</a>
                <ul>
                  <li class="submenu-item">
                    <a href="https://yns.daragroups.com/" target="_blank">YNS</a>
                  </li>
                </ul>
              </li>
              <li class="dropdown-submenu">
                <a>BLOCKCHAIN</a>
                <ul>
                  <li class="submenu-item">
                    <a href="https://kyns.daragroups.com/" target="_blank">KYNS</a>
                  </li>
                  <li class="submenu-item">
                    <a href="https://abn.daragroups.com/" target="_blank">ABN</a>
                  </li>
                </ul>
              </li>

            </ul>
          </li>
          <li><router-link to="/contacts">Contacts</router-link></li>
          <!-- <li class="nav-label">
            <a href="#"><span>Call Us:</span> (+228) 22 22 69 89</a>
          </li> -->
        </ul>
        <!-- <div class="menu-right">
                    <div class="custom-area" style="padding-right:5px">
                        Boulevard du 13 Janvier<br />Lomé, Togo
                    </div>
                </div> -->
      </div>
    </div>
  </nav>
</template>
<script>
export default {
  name: "TopNav",
};
</script>