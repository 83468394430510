<template>
    <main>
        <section class="section-base">
            <div class="container">
                <div class="row" data-anima="fade-bottom" data-time="1000">
                    <div class="col-lg-6">
                        <div class="title">
                            <h2>Let us know what you have in mind</h2>
                            <p>Contact us now</p>
                        </div>
                        <p>
                            Our team is always available to read from you.
                        </p>
                        <a href="#" class="btn-text active">Write To Us!</a>
                    </div>
                    <div class="col-lg-6">
                        <form class="form-box form-ajax form-ajax-wp" method="post" @submit.prevent="sendMessage" data-email="">
                            <div class="row">
                                <div class="col-lg-6">
                                    <input id="Name" v-model="fullName" placeholder="Full Name" type="text" class="input-text">
                                </div>
                                <div class="col-lg-6">
                                    <input id="Email" v-model="email" placeholder="Email" type="text" class="input-text">
                                </div>
                            </div>
                            <hr class="space-xs" />
                            <textarea id="Message" v-model="message" placeholder="Message" class="input-textarea"></textarea>
                            <button class="btn btn-xs" type="submit">Send message</button>
                        </form>
                        <div style="margin-Top:1%">

                            <div class="success-box" v-if="messageRes">
                                <div class="alert alert-success">
                                    {{ messageRes }}
                                </div>
                            </div>
                            <div class="error-box" v-if="errors.length">
                                <div class="alert alert-warning" v-for="error in errors" :key="error">
                                    {{ error }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>
</template>
<script>
import axios from 'axios'
import validator from 'validator'
export default {
    name: 'Contacts',
    data() {
        return {
            fullName: '',
            email: '',
            message: '',
            errors: [],
            messageRes: null,
        }
    },
    methods: {
        sendMessage() {
            this.errors = []
            if(validator.isEmpty(this.fullName)) this.errors.push('Full name is required!')
            if(validator.isEmpty(this.email)) this.errors.push('Email is required!')
            if(!validator.isEmail(this.email)) this.errors.push('Email must be valid!')
            if(validator.isEmpty(this.message)) this.errors.push('Message is required!')
            if (!this.errors.length) {
                const messageData = {
                    fullName: this.fullName,
                    email: this.email,
                    message: this.message
                }
                let self = this
                axios
                    .post(`${process.env.VUE_APP_API}/api/send/mail`, messageData)
                    .then(res => {
                        self.messageRes = res.data.message
                        self.resetForm()
                    })
                    .catch(err => {})
            }
        },
        resetForm() {
            this.fullName = ''
            this.email = ''
            this.message = ''
        }
    }
}
</script>